.demo-section {
    padding: 4rem 2rem;
    background-color: #101010;
    color: #fff;
    text-align: center;
  
    h2 {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      font-family: 'Fira Code', monospace;
      color: var(--primary-color);
    }
  
    .chat-window {
      background-color: #1e1e1e;
      border: 2px solid #00ff9d;
      border-radius: 10px;
      padding: 1.5rem;
      width: 90%;
      max-width: 600px;
      margin: 0 auto 2rem auto;
      font-family: 'Fira Code', monospace;
      color: var(--text-color);
      text-align: left;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
      
      p {
        margin: 0.5rem 0;
      }
  
      p:nth-child(odd) {
        color: var(--primary-color); /* User chat color */
      }
  
      p:nth-child(even) {
        color: var(--secondary-color); /* Bot chat color */
      }
    }
  
    .cta-button {
      background-color: var(--primary-color);
      color: #101010;
      font-family: 'Fira Code', monospace;
      padding: 0.75rem 2rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1.2rem;
      transition: background-color 0.3s ease;
      
      &:hover {
        background-color: #00d38a;
      }
    }
  }
  