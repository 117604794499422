:root {
  --primary-color: #00ff9d;
  --bg-color: #1e1e1e;
  --text-color: #ffffff;
  --secondary-color: #666666;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Fira Code', monospace;
  margin: 0;
  padding: 0;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}
