.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    background-color: #121212;
    color: var(--text-color);
    text-align: center;
  
    h2 {
      font-family: 'Fira Code', monospace;
      font-size: 3rem;
      color: var(--primary-color);
      margin-bottom: 1.5rem;
    }
  
    p {
      font-size: 1.2rem;
      color: var(--secondary-color);
      font-family: 'Fira Code', monospace;
      max-width: 800px;
      margin-bottom: 2rem;
    }
  
    .cta-button {
      background-color: var(--primary-color);
      color: #121212;
      font-family: 'Fira Code', monospace;
      padding: 1rem 2.5rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1.2rem;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #00d38a;
      }
    }
  
    .terminal {
      background-color: #1e1e1e;
      border: 2px solid var(--primary-color);
      border-radius: 10px;
      padding: 2rem;
      margin-bottom: 2rem;
      max-width: 700px;
      width: 100%;
      font-family: 'Fira Code', monospace;
      color: var(--text-color);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
      text-align: left;
  
      pre {
        margin: 0;
      }
  
      code {
        color: var(--primary-color);
      }
    }
  }
  
  @media (max-width: 768px) {
    .hero-section {
      padding: 2rem 1rem;
  
      h2 {
        font-size: 2rem;
      }
  
      p {
        font-size: 1rem;
      }
  
      .cta-button {
        padding: 0.75rem 2rem;
        font-size: 1rem;
      }
    }
  }
  