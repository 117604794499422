.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #121212;
    color: var(--text-color);
    border-bottom: 2px solid #00ff9d;
    
    h1 {
      font-family: 'Fira Code', monospace;
      font-size: 2rem;
      color: var(--primary-color);
      margin: 0;
      cursor: pointer;
      transition: color 0.3s ease;
  
      &:hover {
        color: #00d38a;
      }
    }
  
    nav {
      ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
  
        li {
          margin-left: 2rem;
          font-family: 'Fira Code', monospace;
          font-size: 1.2rem;
  
          a {
            color: var(--text-color);
            text-decoration: none;
            transition: color 0.3s ease;
  
            &:hover {
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
  
      nav {
        margin-top: 1rem;
        ul {
          flex-direction: column;
          align-items: flex-start;
  
          li {
            margin: 0.5rem 0;
          }
        }
      }
    }
  }
  