.features-section {
    padding: 4rem 2rem;
    background-color: #1e1e1e;
    color: #fff;
    text-align: center;
  
    h2 {
      font-size: 2.5rem;
      margin-bottom: 3rem;
      font-family: 'Fira Code', monospace;
      color: var(--primary-color);
    }
  
    .features-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 2rem;
      max-width: 1200px;
      margin: 0 auto;
    }
  
    .feature-card {
      background-color: #2a2a2a;
      border: 2px solid #00ff9d;
      border-radius: 10px;
      padding: 2rem;
      text-align: center;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.6);
      }
  
      span {
        font-size: 3rem;
        display: block;
        margin-bottom: 1rem;
        color: var(--primary-color);
      }
  
      h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        font-family: 'Fira Code', monospace;
        color: var(--text-color);
      }
  
      p {
        font-size: 1rem;
        color: var(--secondary-color);
        font-family: 'Fira Code', monospace;
      }
    }
  }
  