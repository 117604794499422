/* You can add a simple CSS reset here if needed, or just remove this file */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Optional: Define the body's global font settings, but you might already have this in global.module.scss */
body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Code', monospace; /* Same as in global.module.scss */
  background-color: #1e1e1e; /* Default dark background */
  color: #ffffff; /* Default text color */
}
